import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS file

function MyDoom(){
  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);

    return (
      <div>

        {/* ======= Top Bar ======= */}
        <section id="topbar" className="d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:contact@DoomSquare.com">contact@DoomSquare.com</a>
              </i>
              <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91-9363915500</span>
              </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <a href="index.html" className="twitter"><i className="bi bi-twitter" /></a>
              <a href="index.html" className="facebook"><i className="bi bi-facebook" /></a>
              <a href="index.html" className="instagram"><i className="bi bi-instagram" /></a>
              <a href="index.html" className="linkedin"><i className="bi bi-linkedin" /></a>
            </div>
          </div>
        </section>
        {/* ======= Header ======= */}
        <header id="header" className="d-flex align-items-center">
          <div className="container d-flex align-items-center justify-content-between">
            <h1 className="logo">
              <img src="assets/img1/squares.svg" style={{height: '2em',marginTop:"-10px"}} alt='' />
              <a href="index.html"><span>Doom Square</span></a></h1>
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <a href="index.html" class="logo"><img src="assets/img/logo.png" alt=""></a>*/}
            <nav id="navbar" className="navbar">
              <ul>
                <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                <li><a className="nav-link scrollto" href="#contact">About</a></li>
                <li><a className="nav-link scrollto" href="#featured-services">Services</a></li>
                {/*<li><a className="nav-link scrollto " href="#portfolio">Portfolio</a></li>*/}
                <li><a className="nav-link scrollto" href="#footer">Certificates</a></li>
                <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>{/* .navbar */}
          </div>
        </header>{/* End Header */}
        {/* ======= Hero Section ======= */}
        <section id="hero" className="d-flex align-items-center">
          <div className="container" data-aos="zoom-out" data-aos-delay={100}>

            <h4 style={{color: '#999999'}}>Complete <span style={{color: '#3333dd',fontSize:"xx-large"}}>LoRa</span> based solutions. 
            <br/>Network Servers,Application Servers, Gateways.</h4>
            <span style={{color: '#3333dd',fontSize:"xx-large"}}>Complete solution / Consultation</span>
            <br/><br/>
            <h6>Chirpstack LoRa Server. Gateways. IOT Sensors. Smart meters. LoRaWAN</h6>
            <h6>LoRa,IOT,Chirpstack,smartmeter,LoRaWAN</h6>
            
            <img src='assets/img2/lorawan_logo.png' style={{height:"6em"}} alt='LoRa, Chirpstack, IOT'/>
            <br/><br/>
            <h6>Customized IT &amp; IOT Solutions and consultation</h6>
            <br/>
            {/*<h5 style={{color: '#999', fontStyle: 'italic'}}>Registered under...</h5>*/}
            <img src="assets/img/startupindia.png" style={{height: '5em'}}  alt=''/>
            <img src="assets/img/keralastartupindia.png" style={{height: '5em'}}  alt=''/>
          </div>
        </section>{/* End Hero */}
        
        <main id="main">
          
          
          


          {/* ======= Featured Services Section ======= */}
          <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
              <div className="row">
              <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                    <div className="icon">
                      {/*<i className="bx bx-tachometer" />*/}
                      <img src='assets/img2/lorawan_logo.png' style={{height:"4em"}} alt='LoRa, Chirpstack, IOT'/>
                      
                    </div>
                    <h4 className="title"><a href="index.html">LoRa Network solutions</a></h4>

                    <img src='assets/img2/layout1.png' style={{width:"100%"}} alt='LoRa, Chirpstack, IOT'/>
                    <br/><br/>
                    <img src='assets/img2/mapview.jpg' style={{width:"100%"}} alt='LoRa, Chirpstack, IOT'/>

                  </div>
                </div>
                
                <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                    <div className="icon">
                      {/*<i className="bx bxl-dribbble" />*/}
                      <img src='assets/logos/loralogo.png' style={{height:"4em"}} alt='LoRa, Chirpstack, IOT'/>
                      </div>

                    <h4 className="title"><a href="index.html">LoRa based components</a></h4>
                    <img src='assets/img2/layout2.png' style={{width:"100%"}} alt='LoRa, Chirpstack, IOT'/>
                    
                    <img src='assets/img2/antenna.png' style={{width:"50%"}} alt='LoRa, Chirpstack, IOT'/>
                    <img src='assets/img2/cavityfilter.png' style={{width:"50%"}} alt='LoRa, Chirpstack, IOT'/>
                    <img src='assets/img2/kerlink.png' style={{width:"50%"}} alt='LoRa, Chirpstack, IOT'/>
                    <img src='assets/img2/watermeter.png' style={{width:"50%"}} alt='LoRa, Chirpstack, IOT'/>
                  </div>
                </div>

              </div>
            </div>
          </section>{/* End Featured Services Section */}



{/* ======= Featured Services Section ======= */}
<section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
              <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                    <div className="icon">
                      {/*<i className="bx bx-tachometer" />*/}
                      <img src='assets/logos/loralogo.png' style={{height:"4em"}} alt='LoRa, Chirpstack, IOT'/>
                    </div>
                    <h4 className="title"><a href="index.html">LoRa Network solutions</a></h4>
                    <p className="description">Chirpstack, LoRaWAN, LoRa hardware to server end solutions and consultation service.
                    IOT Sensors. Smart Meters.</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                    <div className="icon"><i className="bx bxl-dribbble" /></div>
                    <h4 className="title"><a href="index.html">Customized Software</a></h4>
                    <p className="description">Software Development solutions. Mobile Apps. IoT based solutions.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                    <div className="icon"><i className="bx bx-file" /></div>
                    <h4 className="title"><a href="index.html">GPS and Fleet Managements</a></h4>
                    <p className="description">GPS hardware, Fleet Managements, GTFS solutions, API services</p>
                  </div>
                </div>
                
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                  <div className="icon-box" data-aos="fade-up" data-aos-delay={400}>
                    <div className="icon"><i className="bx bx-phone-call" /></div>
                    <h4 className="title"><a href="index.html">Mobile Apps</a></h4>
                    <p className="description">Customized mobile apps for various specialized requirements</p>
                  </div>
                </div>
              </div>
            </div>
          </section>{/* End Featured Services Section */}





          {/* ======= Skills Section ======= */}
          <section id="skills" className="skills">
            <div className="container" data-aos="fade-up">
              <div className="row skills-content">
                <div className="col-lg-6">
                  <div className="progress">
                    <span className="skill">Software Developments <i className="val">100%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}  style={{ width: '100%' }} />
                    </div>
                  </div>
                  <div className="progress">
                    <span className="skill">GPS &amp; Fleet Managements <i className="val">90%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}  style={{ width: '90%' }}/>
                    </div>
                  </div>
                  <div className="progress">
                    <span className="skill">LoRa Networks <i className="val">75%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}  style={{ width: '75%' }} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="progress">
                    <span className="skill">Mobile Apps <i className="val">80%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100}   style={{ width: '80%' }}/>
                    </div>
                  </div>
                  <div className="progress">
                    <span className="skill">Energy Meters <i className="val">90%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}   style={{ width: '90%' }}/>
                    </div>
                  </div>
                  <div className="progress">
                    <span className="skill">Content Management <i className="val">55%</i></span>
                    <div className="progress-bar-wrap">
                      <div className="progress-bar" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}   style={{ width: '55%' }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{/* End Skills Section */}

          {/* End Services Section */}
          {/* ======= Testimonials Section ======= */}
          <section id="testimonials" className="testimonials">
            <div className="container" data-aos="zoom-in">
              <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay={100}>
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="assets/img1/squares.svg" className="testimonial-img" alt="" />
                      <h3>GPS/Fleet Managements</h3>
                      <h4>Custom &amp; Solutions</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        GPS based fleet Managements. GTFS Customization. 
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>{/* End testimonial item */}
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="assets/img1/squares.svg" className="testimonial-img" alt="" />
                      <h3>LoRa Networks</h3>
                      <h4>Complete solutions</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Starting from LoRa Enabled hardware, Gateways and LNS and application servers
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>{/* End testimonial item */}
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="assets/img1/squares.svg" className="testimonial-img" alt="" />
                      <h3>Software Development</h3>
                      <h4>Web and Desktop solutions</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Customized software solutions. 
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>{/* End testimonial item */}
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="assets/img1/squares.svg" className="testimonial-img" alt="" />
                      <h3>Mobile Apps</h3>
                      <h4>Including plugin Development</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Mobile apps for various Customized requirements. Native tools.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>{/* End testimonial item */}
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img src="assets/img1/squares.svg" className="testimonial-img" alt="" />
                      <h3>OEM Solutions</h3>
                      <h4>IT related projects</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        End to end project solutions. Turnkey projects. hardware and software.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>{/* End testimonial item */}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div>
          </section>
          {/* End Testimonials Section */}



          {/* End Frequently Asked Questions Section */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Contact Us</h2>
                {/*<h3><span>Contact Us</span></h3>*/}
<br/><br/>
                <div style={{fontSize:"x-large"}}>Welcome to</div>
                <br/>
            <h4 style={{color: '#106eea'}}>DoomSquare Micro Systems Private Limited</h4>


                <p>For business inquiries or support, you can reach us anytime via email or WhatsApp.</p>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-6">
                  <div className="info-box mb-4">
                    <i className="bx bx-map" />
                    <h3>Our Address</h3>
                    <p>No. 4/461, 2nd Floor, Suite No.1488
                      Valamkottil Towers, Judgemukku,
                      Kakkanad,&nbsp;Kochi&nbsp;-&nbsp;682021</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="info-box  mb-4">
                    <i className="bx bx-envelope" />
                    <h3>Email Us</h3>
                    <p>contact@DoomSquare.com</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="info-box  mb-4">
                    <i className="bx bx-phone-call" />
                    <h3>Call Us</h3>
                    <p>+91-9363915500</p>
                  </div>
                </div>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-6 ">
                  {/*<iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>*/}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1964.3853593099943!2d76.33346757316825!3d10.035769774890571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080c46a91c5e09%3A0xbfdbe4aff76918cf!2sValamkottil%20Towers%2C%20Edappally%20-%20Pukkattupady%20Rd%2C%20Judgemukku%2C%20Thrikkakara%2C%20Edappally%2C%20Kochi%2C%20Kerala%20682021!5e0!3m2!1sen!2sin!4v1694970844599!5m2!1sen!2sin" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='a1' />
                </div>
                <div className="col-lg-6">
                  <form action="#" method="post" className="php-email-form">
                    <div className="row">
                      <div className="col form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                      </div>
                      <div className="col form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                      </div>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                    </div>
                    <div className="form-group">
                      <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                  </form>
                </div>
              </div>
            </div>
          </section>{/* End Contact Section */}
        </main>{/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div style={{fontSize: 'large', color: '#33e', fontWeight: 'bold'}}><i style={{color: '#999'}}>Kerala Startup Mission</i> DIPP150709/2023/KSUM3252</div>
                  <div style={{fontSize: 'large', color: '#33e', fontWeight: 'bold'}}><i style={{color: '#999'}}>Startup India</i> DIPP150709</div>
                  {/*
            <h4>Join Our Newsletter</h4>
            <p>so that, we can update you on events</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          */}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>DoomSquare<span>.</span></h3>
                  <p>
                    No. 4/461, 2nd Floor, Suite No.1488<br />
                    Valamkottil Towers, Judgemukku,<br />
                    Kakkanad,&nbsp;Kochi&nbsp;-&nbsp;682021 <br /><br />
                    <strong>Phone:</strong>+91-9363915500 
                    <img src="assets/img/32px-WhatsApp.png" alt='' /><br/>
                    <strong>Email:</strong> contact@DoomSquare.com<br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Home</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">About us</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Services</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Terms of service</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Privacy policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Software Development</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">LoRa based solutions</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">IT Consultant</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">GPS/Fleet Managements</a></li>
                    <li><i className="bx bx-chevron-right" /> <a href="index.html">Mobile Apps</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <p>...</p>
                  <div className="social-links mt-3">
                    <a href="index.html" className="twitter"><i className="bx bxl-twitter" /></a>
                    <a href="index.html" className="facebook"><i className="bx bxl-facebook" /></a>
                    <a href="index.html" className="instagram"><i className="bx bxl-instagram" /></a>
                    <a href="index.html" className="google-plus"><i className="bx bxl-skype" /></a>
                    <a href="index.html" className="linkedin"><i className="bx bxl-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              © Copyright <strong><span>DoomSquare</span></strong>. All Rights Reserved
            </div>
            <div className="credits">
              Designed by <a href="https://DoomSquare.com/">DoomSquare</a>
            </div>
          </div>
        </footer>{/* End Footer */}
        <div id="preloader_"></div>
        <a href="index.html" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </div>
    );
}

export default MyDoom;